import "./App.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  Accueil,
  Checkout,
  EditPayment,
  LoginForm,
  CheckoutMoneris,
  ComplexeCremazi,
  PlaceVictoria,
  ReneLevesque,
  TourCIBC,
  TourKPMG,
  MilleDeLaGauchetiere,
} from "./pages/indexPages";
import ParkingCard from "./pages/ParkingCard";

function App() {
  return (
    <Routes>
      <Route path="/login" element={<LoginForm />} />
      <Route path="/" element={<Accueil />} />
      <Route path="/rene-levesque" element={<ReneLevesque />} />{" "}
      {/* rene-levesque */}
      <Route
        path="/1000-de-la-gauchetiere"
        element={<MilleDeLaGauchetiere />}
      />{" "}
      {/* 1000-de-la-gauchetiere */}
      <Route path="/50-complexe-cremazie" element={<ComplexeCremazi />} />{" "}
      {/* 50-complexe-cremazie */}
      <Route path="/place-victoria" element={<PlaceVictoria />} />{" "}
      {/* place-victoria */}
      <Route path="/tour-cibc" element={<TourCIBC />} /> {/* tour-cibc */}
      <Route path="/tour-kpmg" element={<TourKPMG />} /> {/* tour-kpmg */}
      <Route path="/price" element={<ParkingCard />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/moneris-checkout" element={<CheckoutMoneris />} />
      <Route path="/edit/:ticketNumber" element={<EditPayment />} />
      <Route path="/edit" element={<EditPayment />} />
      {/* Ajoutez ici les autres routes pour les nouvelles pages */}
    </Routes>
  );
}

export default App;
