import React from "react";
import { ParkingFinderFormGlobal } from "../../components/indexComponents";
import Layout from "../../components/basics/Layout";

function PlaceVictoria() {
  return (
    <Layout isBg={true}>
      <ParkingFinderFormGlobal
        adresse={"800 Rue du Square-Victoria, Montréal, QC H4Z 1C3"}
        imgLink={
          "https://groupeshapiro.com/wp-content/uploads/2024/03/800-Rue-Du-Square-Victoria-Montreal-QC-Building-Photo-11-LargeHighDefinition.jpg"
        }
        parkingName={"Place Victoria, Montréal"}
        mapLink={
          "https://www.google.com/maps/place/800+Rue+du+Square-Victoria,+Montr%C3%A9al,+QC+H4Z+1C3/@45.5006963,-73.5645787,17z/data=!4m6!3m5!1s0x4cc91a5b8cc52197:0x8f4140ae48d10906!8m2!3d45.5006926!4d-73.5620038!16s%2Fg%2F11bw4chss9?entry=ttu&g_ep=EgoyMDI0MTIwMi4wIKXMDSoASAFQAw%3D%3D"
        }
        parkingMonerisId={"place-victoria"}
        parkingId={568}
      />
    </Layout>
  );
}

export default PlaceVictoria;
