import React from "react";
import { ParkingFinderFormGlobal } from "../../components/indexComponents";
import Layout from "../../components/basics/Layout";

function ComplexeCremazi() {
  return (
    <Layout isBg={true}>
      <ParkingFinderFormGlobal
        adresse={"50 Rue Crémazie O, Montréal, QC H2P 1A2"}
        imgLink={
          "https://images.loopnet.ca/i2/lBMD_Bd4oddPSd8ke1iJ6-Dfo0X72k6DN8i6EGvINyo/112/50-Boul-Cr%C3%A9mazie-O-Montr%C3%A9al-QC-Photo-principale-1-HighDefinition.jpg"
        }
        parkingName={"Complexe Crémazie, Montréal"}
        mapLink={
          "https://www.google.com/maps/place/Complexe+Place+Cr%C3%A9mazie+Sec/@45.5401328,-73.6446732,17z/data=!3m1!4b1!4m6!3m5!1s0x4cc918e1604df4f7:0x81def299f5b672ad!8m2!3d45.5401328!4d-73.6420983!16s%2Fg%2F1tj4b2dl?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
        }
        parkingMonerisId={"complexe-cremazie"}
        parkingId={1299}
      />
    </Layout>
  );
}

export default ComplexeCremazi;
