import React from "react";
import { ParkingFinderFormGlobal } from "../../components/indexComponents";
import Layout from "../../components/basics/Layout";
import { ParkingImg1000Gauchetiere } from "../../img/index";

function MilleDeLaGauchetiere() {
  return (
    <Layout isBg={true}>
      <ParkingFinderFormGlobal
        adresse={"1000 Rue De la Gauchetière O, Montréal, QC H3B 4W5"}
        imgLink={ParkingImg1000Gauchetiere}
        parkingName={"1000 de la Gauchetière, Montréal"}
        mapLink={
          "https://www.google.com/maps/place/1000+De+La+Gaucheti%C3%A8re,+1000+Rue+De+la+Gaucheti%C3%A8re+O,+Montr%C3%A9al,+QC+H3B+4W5/@45.499409,-73.5664085,16.42z/data=!4m15!1m8!3m7!1s0x4cc91a5d1e3fb39d:0x7934c4a99504768b!2s1000+De+La+Gaucheti%C3%A8re,+1000+Rue+De+la+Gaucheti%C3%A8re+O,+Montr%C3%A9al,+QC+H3B+4W5!3b1!8m2!3d45.4981757!4d-73.5663859!16zL20vMDRrbHhr!3m5!1s0x4cc91a5d1e3fb39d:0x7934c4a99504768b!8m2!3d45.4981757!4d-73.5663859!16zL20vMDRrbHhr?entry=ttu"
        }
        parkingMonerisId={"mille-de-la-gauchetiere"}
        parkingId={569}
      />
    </Layout>
  );
}

export default MilleDeLaGauchetiere;
