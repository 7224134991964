import React from "react";
import Layout from "../components/basics/Layout";
import { CardListItem } from "../components/indexComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { ParkingImg1000Gauchetiere } from "../img/index";

function Accueil() {
  const { t } = useTranslation();
  return (
    <Layout isBg={true}>
      <div className="h-full flex w-full justify-center items-cente p-2">
        <div className="grid gap-8 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 auto-rows-fr pt-10">
          <CardListItem
            imgSrc={ParkingImg1000Gauchetiere}
            imgAlt={"1000 de la Gauchetière"}
            parkingName={"1000 de la Gauchetière"}
            parkingAdress={"1000 Rue De la Gauchetière O, Montréal, QC H3B 4W5"}
            mapLink={
              "https://www.google.com/maps/place/1000+De+La+Gaucheti%C3%A8re,+1000+Rue+De+la+Gaucheti%C3%A8re+O,+Montr%C3%A9al,+QC+H3B+4W5/@45.499409,-73.5664085,16.42z/data=!4m15!1m8!3m7!1s0x4cc91a5d1e3fb39d:0x7934c4a99504768b!2s1000+De+La+Gaucheti%C3%A8re,+1000+Rue+De+la+Gaucheti%C3%A8re+O,+Montr%C3%A9al,+QC+H3B+4W5!3b1!8m2!3d45.4981757!4d-73.5663859!16zL20vMDRrbHhr!3m5!1s0x4cc91a5d1e3fb39d:0x7934c4a99504768b!8m2!3d45.4981757!4d-73.5663859!16zL20vMDRrbHhr?entry=ttu"
            }
            btnLink={"/1000-de-la-gauchetiere"}
          />
          <CardListItem
            imgSrc={
              "https://vortexlisting.vortexsolution.com/DATA/IMMEUBLEIMAGE/645.jpg"
            }
            imgAlt={"Complexe Crémazie"}
            parkingName={"Complexe Crémazie"}
            parkingAdress={"50 Rue Crémazie O, Montréal, QC H2P 1A2"}
            mapLink={
              "https://www.google.com/maps/place/Complexe+Place+Cr%C3%A9mazie+Sec/@45.5401328,-73.6446732,17z/data=!3m1!4b1!4m6!3m5!1s0x4cc918e1604df4f7:0x81def299f5b672ad!8m2!3d45.5401328!4d-73.6420983!16s%2Fg%2F1tj4b2dl?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
            }
            btnLink={"/50-complexe-cremazie"}
          />
          <CardListItem
            imgSrc={
              "https://groupeshapiro.com/wp-content/uploads/2024/03/800-Rue-Du-Square-Victoria-Montreal-QC-Building-Photo-11-LargeHighDefinition.jpg"
            }
            imgAlt={"Place Victoria"}
            parkingName={"Place Victoria"}
            parkingAdress={"800 Rue du Square-Victoria, Montréal, QC H4Z 1C3"}
            mapLink={
              "https://www.google.com/maps/place/800+Rue+du+Square-Victoria,+Montr%C3%A9al,+QC+H4Z+1C3/@45.5006963,-73.5645787,17z/data=!4m6!3m5!1s0x4cc91a5b8cc52197:0x8f4140ae48d10906!8m2!3d45.5006926!4d-73.5620038!16s%2Fg%2F11bw4chss9?entry=ttu&g_ep=EgoyMDI0MTIwMi4wIKXMDSoASAFQAw%3D%3D"
            }
            btnLink={"/place-victoria"}
          />
          <CardListItem
            imgSrc={
              "https://vortexlisting.vortexsolution.com/DATA/IMMEUBLEIMAGE/1291.jpg"
            }
            imgAlt={"René-Lévesque"}
            parkingName={"René-Lévesque"}
            parkingAdress={"1100 Boul. René-Lévesque O, Montréal, QC H3B 4N4"}
            mapLink={
              "https://www.google.com/maps/place/%C3%A9difice+La+Laurentienne,+1100+Boul.+Ren%C3%A9-L%C3%A9vesque+O,+Montr%C3%A9al,+QC+H3B+4N4/@45.4980828,-73.5728896,17z/data=!4m6!3m5!1s0x4cc91a42c1b52609:0xfb8979218b74d0c2!8m2!3d45.4980791!4d-73.5703147!16s%2Fm%2F0x0v03j?entry=ttu&g_ep=EgoyMDI0MTIwMi4wIKXMDSoASAFQAw%3D%3D"
            }
            btnLink={"/rene-levesque"}
          />
          <CardListItem
            imgSrc={
              "https://cdn.commercialcafe.com/images/38714f0f-961c-4492-9bcf-09412c1a4703/55%20%20CIBC%20u00c9difice%20(Stu00e9phan%20Poulin5695).jpg"
            }
            imgAlt={"Tour CIBC"}
            parkingName={"Tour CIBC"}
            parkingAdress={"1155 Boul. René-Lévesque O, Montréal, QC H3B 2K2"}
            mapLink={
              "https://www.google.com/maps/place/Tour+CIBC,+1155+Ren%C3%A9-L%C3%A9vesque+Blvd+W,+Montreal,+QC+H3B+2K2/@45.4986308,-73.5735397,17z/data=!4m6!3m5!1s0x4cc91a42fa2b21bb:0x188f93c1d6a94626!8m2!3d45.4986271!4d-73.5709648!16s%2Fg%2F1yfdrm_m2?entry=ttu&g_ep=EgoyMDI0MTIwMi4wIKXMDSoASAFQAw%3D%3D"
            }
            btnLink={"/tour-cibc"}
          />
          <CardListItem
            imgSrc={
              "https://vortexlisting.vortexsolution.com/DATA/IMMEUBLEIMAGE/2585.jpg"
            }
            imgAlt={"Tour KPMG"}
            parkingName={"Tour KPMG"}
            parkingAdress={
              "600 Blvd. De Maisonneuve Ouest, Montreal, QC H3A 3J2"
            }
            mapLink={
              "https://www.google.com/maps/place/600+Blvd.+De+Maisonneuve+Ouest,+Montreal,+QC+H3A+3J2/@45.5041046,-73.5735785,17z/data=!3m1!4b1!4m6!3m5!1s0x4cc91a45d88446b1:0xa9685b964a5e3fb5!8m2!3d45.5041009!4d-73.5710036!16s%2Fg%2F11c2g05dg_?entry=ttu&g_ep=EgoyMDI0MTIwMi4wIKXMDSoASAFQAw%3D%3D"
            }
            btnLink={"/tour-kpmg"}
          />
        </div>
      </div>
    </Layout>
  );
}

export default Accueil;
