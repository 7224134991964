import React from "react";
import { ParkingFinderFormGlobal } from "../../components/indexComponents";
import Layout from "../../components/basics/Layout";

function TourKPMG() {
  return (
    <Layout isBg={true}>
      <ParkingFinderFormGlobal
        adresse={"600 Blvd. De Maisonneuve Ouest, Montreal, QC H3A 3J2"}
        imgLink={
          "https://vortexlisting.vortexsolution.com/DATA/IMMEUBLEIMAGE/2585.jpg"
        }
        parkingName={"Tour KPMG, Montreal"}
        mapLink={
          "https://www.google.com/maps/place/600+Blvd.+De+Maisonneuve+Ouest,+Montreal,+QC+H3A+3J2/@45.5041046,-73.5735785,17z/data=!3m1!4b1!4m6!3m5!1s0x4cc91a45d88446b1:0xa9685b964a5e3fb5!8m2!3d45.5041009!4d-73.5710036!16s%2Fg%2F11c2g05dg_?entry=ttu&g_ep=EgoyMDI0MTIwMi4wIKXMDSoASAFQAw%3D%3D"
        }
        parkingMonerisId={"tour-kpmg"}
        parkingId={501}
      />
    </Layout>
  );
}

export default TourKPMG;
