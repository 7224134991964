import React from "react";

import { MonerisCheckout } from "../components/indexComponents";
import Layout from "../components/basics/Layout";

function Checkout() {
  return (
    <Layout>
      <MonerisCheckout />
    </Layout>
  );
}

export default Checkout;
