import React from "react";
import { ParkingFinderFormGlobal } from "../../components/indexComponents";
import Layout from "../../components/basics/Layout";

function TourCIBC() {
  return (
    <Layout isBg={true}>
      <ParkingFinderFormGlobal
        adresse={"1155 Boul. René-Lévesque O, Montréal, QC H3B 2K2"}
        imgLink={
          "https://cdn.commercialcafe.com/images/38714f0f-961c-4492-9bcf-09412c1a4703/55%20%20CIBC%20u00c9difice%20(Stu00e9phan%20Poulin5695).jpg"
        }
        parkingName={"Tour CIBC, Montréal"}
        mapLink={
          "https://www.google.com/maps/place/Tour+CIBC,+1155+Ren%C3%A9-L%C3%A9vesque+Blvd+W,+Montreal,+QC+H3B+2K2/@45.4986308,-73.5735397,17z/data=!4m6!3m5!1s0x4cc91a42fa2b21bb:0x188f93c1d6a94626!8m2!3d45.4986271!4d-73.5709648!16s%2Fg%2F1yfdrm_m2?entry=ttu&g_ep=EgoyMDI0MTIwMi4wIKXMDSoASAFQAw%3D%3D"
        }
        parkingMonerisId={"tour-cibc"}
        parkingId={566}
      />
    </Layout>
  );
}

export default TourCIBC;
