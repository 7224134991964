import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment-timezone";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faChevronCircleRight,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import "../CSS/ParkingCard.css";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Layout from "../components/basics/Layout";

function ParkingCard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    startDate,
    arrivalTime,
    imgLink,
    adresse,
    parkingName,
    mapLink,
    parkingMonerisId,
    parkingId,
  } = location.state || {}; // Extraire startDate et arrivalTime
  const [validTillDate, setValidTillDate] = useState(null);
  const [price, setPrice] = useState(18);
  const [show, setShow] = useState(false);
  const [ticket, setTicket] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(false);
  const [endDate, setEndDate] = useState(null);

  // Créer des références pour email et phoneNumber
  const emailRef = useRef("");
  const phoneNumberRef = useRef("");

  // Mettre à jour les références à chaque changement des états
  useEffect(() => {
    emailRef.current = email;
    phoneNumberRef.current = phoneNumber;
  }, [email, phoneNumber]);

  // Calculer la date valide jusqu'à
  useEffect(() => {
    if (startDate) {
      const newDate = new Date(startDate);
      const hoursToAdd = 12;
      newDate.setHours(newDate.getHours() + hoursToAdd); // Ajouter les heures
      setEndDate(newDate);
      setValidTillDate(
        newDate.toLocaleString("fr-FR", {
          dateStyle: "medium",
          timeStyle: "short",
        }), // Inclure l'heure
      );
    }
  }, [startDate]);

  const formattedStartDate = startDate
    ? new Date(startDate).toLocaleString("fr-FR", { dateStyle: "medium" })
    : "Date non sélectionnée";

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const generateParkingInfo = (startDate) => {
    const beginDate = new Date(startDate);
    const endDate = new Date(beginDate);
    endDate.setDate(beginDate.getDate() + 1);
    beginDate.setHours(8, 0, 0); // Set start time to 8:00 AM
    endDate.setHours(8, 0, 0); // Set end time to 8:00 AM the next day

    return {
      id: 1,
      nom: "1000 De La Gauchetière",
      adresse: "1000 Rue De la Gauchetière O, Montréal, QC H3B 4W5",
      imageLink:
        "https://img.lapresse.ca/924x615/201705/12/1401641-caisse-depot-mettra-bientot-vente.jpg",
      prix: price,
      duree: "12 heures",
      valide: endDate.toLocaleString("fr-FR", {
        dateStyle: "medium",
        timeStyle: "short",
      }),
      lat: 45.49817526069414,
      lng: -73.56637633437992,
    };
  };

  const parking = generateParkingInfo(startDate);
  /* console.log(parking); */

  // #region MONERIS -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- Début

  // Fonction appelée lorsque la page est chargée
  const myPageLoad = () => {
    console.log("La page de paiement Moneris est chargée.");
    setIsLoading(false);
  };

  // Fonction appelée lorsque la transaction est annulée
  const myCancelTransaction = () => {
    setTicket(null);
  };

  // Fonction appelée lorsque le client demande un reçu
  const myPaymentReceipt = () => {
    console.log("L'utilisateur a demandé un reçu.");
  };

  // Fonction appelée lorsque la transaction est terminée
  const myPaymentComplete = () => {
    setIsLoading(true);
    sendTicket();
  };

  const sendTicket = async () => {
    try {
      console.log("Email:", emailRef.current);
      console.log("Phone:", phoneNumberRef.current);
      const timeZone = "America/Toronto";
      const formattedBeginDate = moment(startDate)
        .tz(timeZone)
        .format("YYYY-MM-DDTHH:mm:ss");
      const formattedEndDate = moment(endDate)
        .tz(timeZone)
        .format("YYYY-MM-DDTHH:mm:ss");

      const ticketData = {
        email: emailRef.current,
        phone: phoneNumberRef.current, // TODO formatter le numero tjr de la meme facon 8192146320
        beginDate: formattedBeginDate,
        endDate: formattedEndDate,
        price: price,
        parkingId: parkingId,
      };
      console.log("ticket details : ", ticketData);

      const ticketResponse = await axios.post(
        /* "http://localhost:3002/api/v1/ticket", */
        "https://backend-pema.vercel.app/api/v1/ticket",
        ticketData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      if (ticketResponse.status === 201) {
        /* console.log("Ticket created and sent to:", email); */

        // Rediriger l'utilisateur vers l'URL du ticket
        const ticketUrl = ticketResponse.data.ticket.url;
        window.location.href = ticketUrl; // Redirection
      } else {
        console.error("Failed to create ticket:", ticketResponse.data);
      }
    } catch (error) {
      console.error("Payment error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Initialiser Moneris Checkout si le ticket est disponible
    if (ticket) {
      const myCheckout = new window.monerisCheckout();
      myCheckout.setMode("prod"); // 'prod' en production
      myCheckout.setCheckoutDiv("monerisCheckout");

      // Rappels pour gérer les événements
      myCheckout.setCallback("page_loaded", myPageLoad);
      myCheckout.setCallback("cancel_transaction", myCancelTransaction);
      myCheckout.setCallback("payment_receipt", myPaymentReceipt);
      myCheckout.setCallback("payment_complete", myPaymentComplete);

      // Démarrer la page de paiement Moneris avec le ticket
      myCheckout.startCheckout(ticket);
    }
  }, [ticket]); // Exécuter cet effet lorsque `ticket` change

  // Fonction pour déclencher la demande de ticket au backend
  const handleCheckout = async () => {
    setIsLoading1(true);
    var userLang = localStorage.getItem("userLanguage") || "fr";

    const txnData = {
      txn_total: 20.69, // Montant de la transaction
      language: userLang, // Langue de la page de paiment à initié
      parking: parkingMonerisId,
      email: email,
      phone: phoneNumber,
    };

    try {
      const response = await fetch(
        /* "http://localhost:3002/api/v1/moneris/preload", */
        "https://backend-pema.vercel.app/api/v1/moneris/preload",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(txnData),
        },
      );

      const data = await response.json();

      if (data.ticket) {
        setIsLoading1(false);
        setTicket(data.ticket); // Stocker le ticket pour démarrer le checkout
      } else {
        console.error(data.error);
        setIsLoading1(false);
        window.location.href = "/";
      }
    } catch (error) {
      console.error(error);
      setIsLoading1(false);
      alert("Une erreur est survenue, veuillez réessayer");
      window.location.href = "/";
    }
  };

  // #endregion MONERIS -=-=-=-=-=-=-=-=-=- Fin

  return (
    <Layout className={"ParkingCard-Layout"}>
      {!ticket && (
        <div className="w-full lg:px-14 p-4 lg:py-5 pb-20">
          <button
            onClick={() => {
              navigate("/");
            }}
            className="flex items-center  w-full justify-start lg:mb-5"
          >
            <div className="bg-customblue text-white text-xl px-2 rounded-full">{`<`}</div>
          </button>

          <div className="flex flex-col lg:flex-row lg:space-x-10 w-full ">
            <img
              className="lg:w-full mt-3 lg:max-w-[50%] max-w-[full] object-cover max-h-[50vh] lg:max-h-none"
              src={imgLink}
              alt={parkingName}
            />
            <div className="flex flex-col space-y-4 lg:space-y-10 w-full">
              <div className="flex items-end justify-between  mt-4">
                <h5 className="font-semibold text-2xl ">{parkingName}</h5>
                <button className="text-customblue" onClick={handleShow}>
                  <FontAwesomeIcon icon={faInfoCircle} size="xl" />
                </button>
              </div>
              <span className="text-4xl font-bold">
                {parseFloat(parking.prix).toFixed(2)}$
              </span>
              <div className="text-lg">
                <p>
                  <strong>{t("home:ParkingCard:dateAndHour")} </strong>{" "}
                  {formattedStartDate}, {arrivalTime}
                </p>
                <p>
                  <strong>{t("home:ParkingCard:validTill")}</strong>{" "}
                  {validTillDate}
                </p>
                <p>
                  <strong>{t("home:ParkingCard:duration")}</strong>{" "}
                  {parking.duree}
                </p>
              </div>
              <div className="text-lg">
                <p>{adresse}</p>
                <a href={mapLink}>
                  {t("home:ParkingCard:seeOnMap")}{" "}
                  <FontAwesomeIcon icon={faArrowRight} />
                </a>
              </div>
              {/* <button
                className="bg-customblue text-white py-4 px-4 rounded-md text-xl flex space-x-3 uppercase font-semibold  items-center justify-center mt-4 lg:w-6/12"
                onClick={() => goCheckout(parking.id)}
              >
                <span>{t("home:ParkingCard:prepay")}</span>
                <FontAwesomeIcon icon={faChevronCircleRight} />
              </button> */}
              <button
                className="bg-customblue text-white py-4 px-4 rounded-md text-xl flex space-x-3 uppercase font-semibold  items-center justify-center mt-4 lg:w-6/12"
                onClick={handleCheckout}
              >
                <span>{t("home:ParkingCard:prepay")}</span>
                <FontAwesomeIcon icon={faChevronCircleRight} />
              </button>
            </div>
          </div>
        </div>
      )}

      {ticket && (
        <div className="bg-white text-base space-y-4 lg:m-4 m-2 rounded-2xl w-full">
          {!isLoading && (
            <div className="form-custom p-[47px] pb-0 max-w-[567px] mx-auto">
              <h3 className="font-[arial] text-lg font-bold">Information</h3>
              <div className="mb-3 flex flex-col gap-0">
                <label htmlFor="email" className="form-label font-[arial]">
                  {t("home:Checkout:email")}
                </label>
                <input
                  type="email"
                  className="py-3 px-3 border border-gray-300 rounded-md xl:w-full"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t("home:Checkout:emailPlaceholder")}
                />
                {emailError && <div className="text-danger">{emailError}</div>}
              </div>

              <div className="mb-3 flex flex-col gap-0">
                <label htmlFor="phoneNumber" className="form-label ">
                  {t("home:Checkout:phone")}
                </label>
                <input
                  type="tel"
                  className="py-3 px-3 border border-gray-300 rounded-md lg:w-full"
                  id="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="123-456-7890"
                />
                {phoneError && <div className="text-danger">{phoneError}</div>}
              </div>
            </div>
          )}

          <div id="monerisCheckout" className="position-relative">
            {/* La page de paiement sera chargée ici avec Moneris */}
          </div>
        </div>
      )}

      {isLoading1 && (
        <div className="h-screen flex justify-center items-center gap-4 bg-white z-20 absolute w-screen">
          <div
            className="inline-block h-8 w-8 animate-spinner-grow rounded-full bg-current align-[-0.125em] opacity-1 text-customblue"
            style={{ animationDelay: "0ms" }}
            role="status"
          ></div>
          <div
            className="inline-block h-8 w-8 animate-spinner-grow rounded-full bg-current align-[-0.125em] opacity-1 text-customblue"
            style={{ animationDelay: "200ms" }}
            role="status"
          ></div>
          <div
            className="inline-block h-8 w-8 animate-spinner-grow rounded-full bg-current align-[-0.125em] opacity-1 text-customblue"
            style={{ animationDelay: "400ms" }}
            role="status"
          ></div>
        </div>
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("home:ParkingCard:moreInformation")}</Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-customblue bleu-pema-fonce"
            onClick={handleClose}
          >
            {t("home:ParkingCard:close")}
          </button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}

export default ParkingCard;
