import React from "react";
import NavBarLayout from "./NavBarLayout";
import bg from "../../img/backgroundtest.png";

function Layout({ children, isBg, className }) {
  return (
    <div
      style={{
        backgroundImage: `url(${isBg ? bg : ""})`, // Correct template literal usage
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      className={`bg-white w-screen min-h-screen font-poppins pt-20  px-4 lg:px-14 flex  flex-col  lg:items-center ${isBg ? "overflow-hidden" : "overflow-y-scroll"} ${className}`}
    >
      <NavBarLayout isBg={isBg} />
      {children}
    </div>
  );
}

export default Layout;
