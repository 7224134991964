import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export default function CardListItem({
  imgSrc,
  imgAlt,
  parkingName,
  parkingAdress,
  btnTexte,
  btnLink,
  mapLink,
}) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] md:max-w-xl md:flex-row">
      <img
        className="h-96 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
        src={imgSrc}
        alt={imgAlt}
      />
      <div className="flex flex-col justify-between p-6 gap-2">
        <h5 className="mb-2 text-xl font-semibold text-customblue ">
          {parkingName}
        </h5>
        <p className="mb-0 text-base text-neutral-600 ">{parkingAdress}</p>
        <a href={mapLink} className="mb-2">
          {t("home:ParkingCard:seeOnMap")}{" "}
          <FontAwesomeIcon icon={faArrowRight} />
        </a>
        <button className="bg-customblue text-white font-semibold hover:text-white py-2 px-4 rounded">
          <a href={btnLink} rel="noopener noreferrer" className="">
            {btnTexte ? btnTexte : t("home:ParkingFinderForm:btnGetPrice")}
          </a>
        </button>
      </div>
    </div>
  );
}
