import React, { useEffect, useState } from "react";

const MonerisCheckout = () => {
  const [ticket, setTicket] = useState(null);

  // Fonction appelée lorsque la page est chargée
  const myPageLoad = () => {
    console.log("La page de paiement Moneris est chargée.");
  };

  // Fonction appelée lorsque la transaction est annulée
  const myCancelTransaction = () => {
    console.log("L'utilisateur a annulé la transaction.");
  };

  // Fonction appelée lorsque le client demande un reçu
  const myPaymentReceipt = () => {
    console.log("L'utilisateur a demandé un reçu.");
  };

  // Fonction appelée lorsque la transaction est terminée
  const myPaymentComplete = () => {
    console.log("L'utilisateur a payé.");
  };

  useEffect(() => {
    // Initialiser Moneris Checkout si le ticket est disponible
    if (ticket) {
      const myCheckout = new window.monerisCheckout();
      myCheckout.setMode("qa"); // 'prod' en production
      myCheckout.setCheckoutDiv("monerisCheckout");

      // Rappels pour gérer les événements
      myCheckout.setCallback("page_loaded", myPageLoad);
      myCheckout.setCallback("cancel_transaction", myCancelTransaction);
      myCheckout.setCallback("payment_receipt", myPaymentReceipt);
      myCheckout.setCallback("payment_complete", myPaymentComplete);

      // Démarrer la page de paiement Moneris avec le ticket
      myCheckout.startCheckout(ticket);
    }
  }, [ticket]); // Exécuter cet effet lorsque `ticket` change

  // Fonction pour déclencher la demande de ticket au backend
  const handleCheckout = async () => {
    var userLang = localStorage.getItem("userLanguage") || "fr";
    if (userLang !== "fr" || userLang !== "en") {
      localStorage.setItem("userLanguage", "fr");
      userLang = "fr";
      console.log("Nice try !");
    }
    const txnData = {
      txn_total: 10.0, // Montant de la transaction
      language: userLang, // Langue de la page de paiment à initié
      parking: "p1-test-003",
      // ... autres données facultatives ...
    };

    try {
      const response = await fetch(
        "http://localhost:3002/api/v1/moneris/preload",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(txnData),
        },
      );

      const data = await response.json();

      if (data.ticket) {
        setTicket(data.ticket); // Stocker le ticket pour démarrer le checkout
      } else {
        console.error(data.error); // Gérer les erreurs
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <button className="btn-primary" onClick={handleCheckout}>
        Payer avec Moneris
      </button>
      {ticket && (
        <div id="monerisCheckout">
          {/* La page de paiement sera chargée ici avec Moneris */}
        </div>
      )}
    </div>
  );
};

export default MonerisCheckout;
