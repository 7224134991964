import React from "react";
import { ParkingFinderFormGlobal } from "../../components/indexComponents";
import Layout from "../../components/basics/Layout";

function ReneLevesque() {
  return (
    <Layout isBg={true}>
      <ParkingFinderFormGlobal
        adresse={"1100 Boul. René-Lévesque O, Montréal, QC H3B 4N4"}
        imgLink={
          "https://images.loopnet.ca/i2/HqZkK4Bg3Sp0U5Grw4lDa7J36ZYn5Dv7aRKq74gaGZw/116/1100-Boul-Ren%C3%A9-L%C3%A9vesque-O-Montr%C3%A9al-QC-Photo-du-b-timent-1-LargeHighDefinition.jpg"
        }
        parkingName={"René-Lévesque, Montréal"}
        mapLink={
          "https://www.google.com/maps/place/%C3%A9difice+La+Laurentienne,+1100+Boul.+Ren%C3%A9-L%C3%A9vesque+O,+Montr%C3%A9al,+QC+H3B+4N4/@45.4980828,-73.5728896,17z/data=!4m6!3m5!1s0x4cc91a42c1b52609:0xfb8979218b74d0c2!8m2!3d45.4980791!4d-73.5703147!16s%2Fm%2F0x0v03j?entry=ttu&g_ep=EgoyMDI0MTIwMi4wIKXMDSoASAFQAw%3D%3D"
        }
        parkingMonerisId={"rene-levesque"}
        parkingId={574}
      />
    </Layout>
  );
}

export default ReneLevesque;
